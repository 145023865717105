
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'MobileSortMenu',
  props: {
    sorts: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    activeSort: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    function emitSortChange(selectedSort: string) {
      emit('sortChange', selectedSort);
    }

    return {
      emitSortChange,
    };
  },
});
